import { createSlice } from '@reduxjs/toolkit';
import { RootStore } from '../../types';
import { TFeedingProteinsItem } from '../../../models/feeding';
import { feedingFatsApi } from '../../services/feeding/fats';

interface FatsFeedingState {
  items: Array<TFeedingProteinsItem>;
}

const initialState: FatsFeedingState = {
  items: [],
};

const { getFeedingFats } = feedingFatsApi.endpoints;

const slice = createSlice({
  name: 'fatsFeeding',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(
      getFeedingFats.matchFulfilled,
      (state: FatsFeedingState, { payload }) => {
        state.items = payload;
      },
    ),
});
export const selectFeedingFats = (state: RootStore): [] =>
  state.fatsFeeding.items;

export const { reducer: feedingFatsReducer, actions: feedingFatsActions } =
  slice;
